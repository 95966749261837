import React from 'react';
import './NewsPostList.scss';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import Section from '@/components/Section/Section';
import { COLORS } from '@/constants';
import Title from '@/components/Title/Title';
import NewsPostCard from '@/components/NewsPostCard/NewsPostCard';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import { graphql, useStaticQuery } from 'gatsby';
import { findLocaleItems } from '../../helpers';

const { WHITE, GREEN } = COLORS;

const STRATES = [
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: GREEN,
		width: '25%',
		withIllu: true
	},
	{
		color: WHITE,
		width: '25%'
	}
];

const NewsPostList = ({ blockTitle, locale }) => {
	const data = useStaticQuery(
		graphql`
			query NewsPostsQuery {
				newsPosts: allStrapiNewsposts(sort: { fields: date, order: DESC }, limit: 25) {
					nodes {
						date
						twitterNews
						tweetLink
						linkedInNews
						linkedInLink
						cover {
							childImageSharp {
								fluid(maxWidth: 250, quality: 100) {
									src
									srcSet
									base64
								}
							}
						}
						NewsGroup {
							locales {
								locale
								localeName
							}
							slug
							subtitle
							title
						}
					}
				}
			}
		`
	);
	const { title } = findLocaleItems(locale)(blockTitle);
	return (
		<Section strates={STRATES} className="news-post-section" isNews>
			<ContentWrapper>
				<section className="news-posts">
					<Title level={2}>{title}</Title>
					<ul className="news-posts-list">
						{data.newsPosts.nodes.map((data, i) => {
							const props = {
								index: i,
								isTwitterNews: data.twitterNews,
								tweetLink: data.tweetLink,
								isLinkedInNews: data.linkedInNews,
								linkedInLink: data.linkedInLink,
								cover: data.cover,
								date: data.date,
								slug: data.slug,
								...filterLocaleItems(locale)(data.NewsGroup)
							};
							return <NewsPostCard {...props} key={i} />;
						})}
					</ul>
				</section>
			</ContentWrapper>
		</Section>
	);
};

export default NewsPostList;
