import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import './Team.scss';
import Section from '@/components/Section/Section';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import { COLORS } from '@/constants';
import Title from '@/components/Title/Title';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import Img from 'gatsby-image';
import LinkedIn from '@/images/linkedin.inline.svg';
import { findLocaleItems } from '../../helpers';

const { WHITE, LIGHTGREY } = COLORS;

const DEFAULT_MAX_PEOPLE = 9;

const STRATES = [
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: LIGHTGREY,
		width: '25%'
	},
	{
		color: WHITE,
		width: '50%'
	}
];

const Team = ({ locale, blockTitle }) => {
	const [maxPeople, setMaxPeople] = useState(DEFAULT_MAX_PEOPLE);
	const data = useStaticQuery(
		graphql`
			query TeamQuery {
				employees: allStrapiEmployees(sort: { fields: employeeOrder }) {
					nodes {
						fullname
						linkedin
						TeamJobTitle {
							job
							locales {
								locale
								localeName
							}
						}
						photo {
							childImageSharp {
								fixed(width: 280) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
		`
	);
	const { title, subtitle } = findLocaleItems(locale)(blockTitle);
	return (
		<Section strates={STRATES} className="team">
			<ContentWrapper>
				<Title level={3}>{title}</Title>
				<p>{subtitle}</p>
				<ul className="team-list">
					{data.employees.nodes.slice(0, maxPeople).map(({ fullname, linkedin, photo, TeamJobTitle }, i) => {
						const { job } = filterLocaleItems(locale)(TeamJobTitle);
						return (
							<li className="employee" key={i}>
								<div className="picture">
									<a href={linkedin}>
										<LinkedIn className="linkedin" width={80} height={80} fill="#fff" />
									</a>
									<Img fixed={photo.childImageSharp.fixed} alt={fullname} />
								</div>
								<div>
									<strong>{fullname}</strong>
									<p>{job}</p>
								</div>
							</li>
						);
					})}
				</ul>
				{!(data.employees.nodes.length === maxPeople) && (
					<button className="see-more" onClick={() => setMaxPeople(data.employees.nodes.length)}>
						Voir plus
					</button>
				)}
			</ContentWrapper>
		</Section>
	);
};

export default Team;
