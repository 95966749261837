import './FeaturesPoints.scss';
import React, { useContext } from 'react';
import Title from '@/components/Title/Title';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import { useStaticQuery, graphql } from 'gatsby';
import { COLORS } from '@/constants';
import { filterLocaleNodes, findLocaleItems } from '../../helpers';
import Img from 'gatsby-image';
import Section from '../Section/Section';
import { IsMobileContext } from '../../contexts/IsMobileContext';

const { WHITE, GREEN, GREY } = COLORS;

const BG_STRATES = [
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: GREEN,
		withIllu: true,
		width: '25%'
	}
];
const BG_STRATES_MOBLIE = [
	{
		color: GREY,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: GREEN,
		withIllu: true,
		width: '25%'
	}
];

const FeaturesPoints = ({ locale }) => {
	const { isMobile } = useContext(IsMobileContext);

	const {
		strapiBlocks: { blockTitle },
		allStrapiFeaturespoints: { nodes: featureNodes }
	} = useStaticQuery(graphql`
		{
			allStrapiFeaturespoints {
				nodes {
					picture {
						childImageSharp {
							fixed(width: 365) {
								src
								srcSet
								base64
								width
							}
						}
					}
					content {
						paragraphe
						title
						locales {
							locale
						}
					}
				}
			}
			strapiBlocks(name: { eq: "FEATURES_POINTS" }) {
				blockTitle {
					locales {
						locale
					}
					title
				}
			}
		}
	`);

	const localizedFeaturesNodes = filterLocaleNodes(locale, 'content')(featureNodes);
	const { title } = findLocaleItems(locale, 'title')(blockTitle);
	return (
		<Section strates={isMobile ? BG_STRATES_MOBLIE : BG_STRATES} className="features-points">
			<ContentWrapper className="edito-wrapper">
				<Title level={2} className="features-points__title">
					{title}
				</Title>
				<ul>
					{localizedFeaturesNodes.map(({ content: { title: featureTitle, paragraphe }, picture }, i) => (
						<li key={i} className="block">
							<p className="block-number">
								{i + 1}
								<span className="block-number-dot">.</span>
							</p>

							<div className="block-text">
								<Title level={3} className="block-title">
									{featureTitle}
								</Title>
								<div className="block-subtitle">{paragraphe}</div>
							</div>
							<Img className="block-image" fixed={picture.childImageSharp.fixed} alt={featureTitle} />
						</li>
					))}
				</ul>
			</ContentWrapper>
		</Section>
	);
};

export default FeaturesPoints;
