import BubbleSliderBlock from '@/components/BubbleSliderBlock/BubbleSliderBlock';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { chunk } from 'lodash';
import { COLORS } from '@/constants.js';

const { WHITE, YELLOW } = COLORS;

const strates = [
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: YELLOW,
		width: '25%',
		withIllu: true
	},
	{
		color: WHITE,
		width: '50%'
	}
];

const SciencePartnership = () => {
	const data = useStaticQuery(
		graphql`
			{
				allStrapiSciencespartnership {
					nodes {
						id
						name
						link
						logo {
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		`
	);

	const slidesData = chunk(data.allStrapiSciencespartnership.nodes, 5);

	return (
		<BubbleSliderBlock
			key="distinctions"
			title={'Nos partenariat scientifiques'}
			slides={slidesData}
			sliderPosition="RIGHT"
			strates={strates}
		/>
	);
};

export default SciencePartnership;
