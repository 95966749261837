import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import './Values.scss';
import Section from '@/components/Section/Section';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import { COLORS } from '@/constants';
import Title from '@/components/Title/Title';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import { findLocaleItems } from '../../helpers';
import { size } from 'lodash/fp';
import { Animated } from 'react-animated-css';
import useVisibiltySensor from '@/hooks/useVisibiltySensor';

const { WHITE, GREEN } = COLORS;

const STRATES = [
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: GREEN,
		width: '25%',
		withIllu: true
	}
];

const Values = ({ locale, blockTitle }) => {
	const valueRef = useRef(null);
	const [isVisible] = useVisibiltySensor(valueRef);
	const data = useStaticQuery(
		graphql`
			query ValueQuery {
				values: allStrapiValues(sort: { fields: id }) {
					nodes {
						valueGroup {
							title
							paragraph
							locales {
								localeName
								locale
							}
						}
						title
						valuePicture {
							url
						}
					}
				}
			}
		`
	);
	const { title } = findLocaleItems(locale)(blockTitle);
	return (
		<Section strates={STRATES} className="values">
			<ContentWrapper>
				<Title level={3}>{title}</Title>
				<ul className="value-list" ref={valueRef}>
					{data.values.nodes.map(({ valueGroup, valuePicture }, i) => {
						const { title, paragraph } = filterLocaleItems(locale)(valueGroup);
						return (
							<Animated
								key={i}
								isVisible={isVisible}
								animationInDelay={200}
								animationIn={i % 2 === 0 ? 'slideInLeft' : 'slideInRight'}>
								<li className="value-item">
									<div className="value-item-inside">
										<Title level={4}>{title}</Title>
										<p>{paragraph}</p>
									</div>
									{size(valuePicture) !== 0 && <img src={valuePicture[0].url} alt={title} />}
								</li>
							</Animated>
						);
					})}
				</ul>
			</ContentWrapper>
		</Section>
	);
};

export default Values;
