import React from 'react';
import './CallToAction.scss';
import Arrow from '../../images/arrow.inline.svg';
import { Link } from 'gatsby';

const CallToAction = ({ children, href }) => (
	<div>
		<Link className="cta" to={href}>
			<div className="btn">
				<Arrow widht={20} height={20} />
			</div>{' '}
			{children}
		</Link>
	</div>
);

export default CallToAction;
