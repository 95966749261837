import { map, find, filter, split, compose } from 'lodash/fp';
import { COLORS } from './constants';

const { LIGHTGREY, GREEN, YELLOW, WHITE } = COLORS;

export const filterLocaleNodes = (locale, propName) =>
	map(item => ({
		...item,
		[propName]: findLocaleItems(locale)(item[propName])
	}));

export const findLocaleItems = (locale = 'fr') =>
	find(({ locales: { locale: localefromItem } }) => locale === localefromItem);

export const filterLocaleItems = (locale = 'fr') =>
	filter(({ locales: { locale: localefromItem } }) => locale === localefromItem);

export const isLocaleMatch = (locale = 'fr') => ({ locales: { locale: localeFromItem } }) => locale === localeFromItem;

export const getStrates = string =>
	compose(
		map(letter => {
			switch (letter) {
				case 'B':
					return {
						color: WHITE,
						width: '25%'
					};
				case 'J':
					return {
						color: YELLOW,
						width: '25%',
						withIllu: true
					};
				case 'j':
					return {
						color: YELLOW,
						width: '25%',
						withIllu: false
					};
				case 'V':
					return {
						color: GREEN,
						width: '25%',
						withIllu: true
					};
				case 'L':
					return {
						color: LIGHTGREY,
						width: '25%',
						withIllu: true
					};
				case 'l':
					return {
						color: LIGHTGREY,
						width: '25%',
						withIllu: false
					};
				default:
					return;
			}
		}),
		split('')
	)(string);
