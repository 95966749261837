import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import './SubsidiaryBlock.scss';
import Section from '@/components/Section/Section';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import CallToAction from '../CallToAction/CallToAction';
import { COLORS } from '@/constants';
import Title from '@/components/Title/Title';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import Img from 'gatsby-image';
import { findLocaleItems } from '../../helpers';
import cn from 'classnames';

const { GREEN, WHITE, YELLOW } = COLORS;

const STRATES = {
	ODD: [
		{
			color: WHITE,
			width: '25%'
		},
		{
			color: WHITE,
			width: '25%'
		},
		{
			color: GREEN,
			width: '25%',
			withIllu: true
		},
		{
			color: WHITE,
			width: '25%'
		}
	],
	EVEN: [
		{
			color: WHITE,
			width: '25%'
		},
		{
			color: YELLOW,
			width: '25%',
			withIllu: true
		}
	]
};

const AffiliatedBlock = ({ locale, blockTitle: blockTitles }) => {
	const data = useStaticQuery(
		graphql`
			query SubsidiariesQuery {
				siteTypes: allStrapiSubsidiaries {
					nodes {
						image {
							childImageSharp {
								fixed(width: 450) {
									...GatsbyImageSharpFixed
								}
							}
						}
						SubsidiaryBlock {
							paragraph
							title
							place
							quote
							cta
							ctaLink
							locales {
								localeName
								locale
							}
						}
						image {
							id
						}
					}
				}
			}
		`
	);
	const { title: blockTitle } = findLocaleItems(locale)(blockTitles);
	return (
		<section className="subsidiary">
			<ul>
				{data.siteTypes.nodes.map(({ image, SubsidiaryBlock }, i) => {
					const { title, place, paragraph, quote, cta, ctaLink } = filterLocaleItems(locale)(SubsidiaryBlock);
					return (
						<li
							className={cn('site-type', {
								viticulteur: i === 0,
								pivoine: i === 1,
								potager: i === 2
							})}
							key={i}>
							<Section strates={i % 2 === 0 ? STRATES.ODD : STRATES.EVEN}>
								<ContentWrapper className="site-type-inner">
									{i === 0 && <Title level={3}>{blockTitle}</Title>}

									<div className="site-type-text">
										<div className="site-type-text-inner">
											<Title level={4}>{title}</Title>
											{/* <Title level={5}>{place}</Title> */}
											<p>{paragraph}</p>
											<p>{quote}</p>
											{cta && <CallToAction href={ctaLink}>{cta}</CallToAction>}
										</div>
									</div>
									<div className="site-type-image">
										<Img fixed={image.childImageSharp.fixed} alt={`${title} - ${place}`} />
									</div>
								</ContentWrapper>
							</Section>
						</li>
					);
				})}
			</ul>
		</section>
	);
};

export default AffiliatedBlock;
