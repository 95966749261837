import BubbleSliderBlock from '@/components/BubbleSliderBlock/BubbleSliderBlock';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { chunk } from 'lodash';
import { COLORS } from '@/constants.js';
import { findLocaleItems } from '../../helpers';

const { WHITE, GREEN } = COLORS;

const strates = [
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: GREEN,
		width: '50%',
		withIllu: true
	}
];

const stratesRealisation = [
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: GREEN,
		width: '25%',
		withIllu: true
	}
];

const Supports = ({ locale, blockTitle: blockTitles, pageName }) => {
	const data = useStaticQuery(
		graphql`
			query SupportsQuery {
				supports: allStrapiSupports {
					nodes {
						name
						logoSupports {
							childImageSharp {
								fluid(maxWidth: 150, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						link
					}
				}
			}
		`
	);
	const { title: blockTitle } = findLocaleItems(locale)(blockTitles);
	const dataSupports = data.supports.nodes.map(({ logoSupports, ...rest }) => ({
		logo: logoSupports,
		...rest
	}));
	const slidesData = chunk(dataSupports, 5);
	return (
		<BubbleSliderBlock
			key="supports"
			title={blockTitle}
			slides={slidesData}
			sliderPosition="LEFT"
			strates={pageName === 'Realisation' ? stratesRealisation : strates}
			isRealisation={pageName === 'Realisation'}
		/>
	);
};

export default Supports;
