import './FAQ.scss';
import React, { useState } from 'react';
import Section from '../Section/Section';
import { findLocaleItems, filterLocaleItems, getStrates } from '../../helpers';
import Title from '../Title/Title';
import { compose, map, property, size } from 'lodash/fp';
import TabContent from './TabContent';
import cn from 'classnames';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import { graphql, useStaticQuery } from 'gatsby';

const FAQ = ({ title = 'Questions fréquentes', locale }) => {
	const [selectedTab, setSelectedTab] = useState(0);

	const faqData = useStaticQuery(graphql`
		query FaqQuery {
			allStrapiFaqcategories {
				nodes {
					questions {
						answer
						question
						locales {
							locale
						}
					}
					tabs {
						tabTitle
						locales {
							locale
						}
					}
				}
			}
		}
	`);
	const { nodes } = faqData.allStrapiFaqcategories;

	const localisedHeaders = compose(map(findLocaleItems(locale)), map(property('tabs')))(nodes);

	const localisedContent = compose(map(filterLocaleItems(locale)), map(property('questions')))(nodes);

	return (
		<Section strates={getStrates('BBLL')} className="faq">
			<ContentWrapper>
				<Title className="faq-title" level={2}>
					{title}
				</Title>
				{size(localisedHeaders) > 1 && (
					<ul className="faq-header">
						{localisedHeaders.map(({ tabTitle }, i) => (
							<li
								key={i}
								className={cn('faq-header-item', { selected: selectedTab === i })}
								onClick={() => setSelectedTab(i)}>
								<button>
									<Title level={3}>{tabTitle}</Title>
								</button>
							</li>
						))}
					</ul>
				)}
				<ul className="faq-content">
					{localisedContent.map((content, i) => (
						<TabContent content={content} key={i} isSelected={selectedTab === i} />
					))}
				</ul>
			</ContentWrapper>
		</Section>
	);
};

export default FAQ;
