import React from 'react';
import HeroHome from '@/components/HeroHome/HeroHome';
import { graphql, useStaticQuery } from 'gatsby';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import { COLORS } from '@/constants.js';

const { WHITE, YELLOW, GREY } = COLORS;

const STRATES = [
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: YELLOW,
		width: '25%',
		withIllu: true
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: GREY,
		width: '25%'
	}
];

const AgronomicExpertise = ({ locale }) => {
	const data = useStaticQuery(
		graphql`
			query AgronomicExpertise {
				strapiAgronomicexpertiseblocks {
					id
					agronomicExpertise {
						Content
						Title
						locales {
							localeName
							locale
						}
						Picture {
							childImageSharp {
								fixed(width: 500) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
		`
	);
	const dataAgronomic = filterLocaleItems(locale)(data.strapiAgronomicexpertiseblocks.agronomicExpertise);

	return (
		<HeroHome
			{...dataAgronomic}
			title={dataAgronomic.Title}
			content={dataAgronomic.Content}
			imgLeft
			picture={dataAgronomic.Picture}
			defaultStrates={STRATES}
			textLeft
			isAgronomic
		/>
	);
};

export default AgronomicExpertise;
