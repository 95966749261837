import React from 'react';
import './BubbleSliderBlock.scss';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import Section from '@/components/Section/Section';
import Title from '@/components/Title/Title';
import Slider from '../Slider/Slider';
import BubbleSlide from './BubbleSlide';
import { SLIDER_DEFAULT_SETTING } from '@/constants.js';
import cn from 'classnames';

const BubbleSliderBlock = ({ title, slides, sliderPosition, strates, isRealisation }) => (
	<Section strates={strates}>
		<ContentWrapper
			className={cn('bubble-slider-block', {
				'slider-right': sliderPosition === 'RIGHT',
				'is-realisation': isRealisation
			})}>
			<div className="bubble-col bubble-slider">
				<Slider key={title} options={SLIDER_DEFAULT_SETTING}>
					{slides.map((items, i) => (
						<BubbleSlide key={i} items={items} />
					))}
				</Slider>
			</div>
			<div className="bubble-col bubble-title">
				<Title level={3}>{title}</Title>
			</div>
		</ContentWrapper>
	</Section>
);

export default BubbleSliderBlock;
