import React from 'react';
import Section from '@/components/Section/Section';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import { graphql, useStaticQuery } from 'gatsby';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import { COLORS } from '@/constants';
import './EditorialHome.scss';
import Illu from '@/images/illu_champ.inline.png';
import Title from '@/components/Title/Title';

const { WHITE, LIGHTGREY, YELLOW } = COLORS;

const BG_STRATES = [
	{
		color: LIGHTGREY,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: YELLOW,
		width: '25%'
	}
];

const EditorialHome = ({ locale }) => {
	const data = useStaticQuery(
		graphql`
			query EditoHomeQuery {
				strapiEditohomes {
					editoBlock {
						title
						paragraph
						locales {
							localeName
							locale
						}
					}
				}
			}
		`
	);
	const { title, paragraph } = filterLocaleItems(locale)(data.strapiEditohomes.editoBlock);

	return (
		<Section strates={BG_STRATES} className="editorial-home">
			<img className="edito-illustration" style={{ height: '100%' }} src={Illu} alt={title} />
			<ContentWrapper className="edito-wrapper">
				<div className="edito-content">
					<Title level={3} className="edito-title">
						{title}
					</Title>
					<p>{paragraph}</p>
				</div>
			</ContentWrapper>
		</Section>
	);
};

export default EditorialHome;
