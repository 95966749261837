import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import './Testimony.scss';
import Section from '@/components/Section/Section';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import { COLORS } from '@/constants';
import Title from '@/components/Title/Title';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import Img from 'gatsby-image';
import { findLocaleItems } from '../../helpers';

const { LIGHTGREY, WHITE, YELLOW } = COLORS;

const STRATES = [
	{
		color: LIGHTGREY,
		width: '25%'
	},
	{
		color: WHITE,
		width: '50%'
	},
	{
		color: YELLOW,
		width: '25%',
		withIllu: true
	}
];

const Testimony = ({ locale, blockTitle: blockTitles }) => {
	const data = useStaticQuery(
		graphql`
			query TestimonyQuery {
				testimony: strapiTestimonies {
					picture {
						childImageSharp {
							fixed(width: 450) {
								...GatsbyImageSharpFixed
							}
						}
					}
					id
					testimonyBlock {
						paragraph
						title
						locales {
							localeName
							locale
						}
					}
				}
			}
		`
	);
	const { title: blockTitle } = findLocaleItems(locale)(blockTitles);
	const { title, paragraph } = filterLocaleItems(locale)(data.testimony.testimonyBlock);
	return (
		<Section strates={STRATES} className="testimony">
			<ContentWrapper className="testimony-wrapper">
				<div className="testimony-text">
					<Title level={3}>{blockTitle}</Title>
					<Title level={4}>{title}</Title>
					<p>{paragraph}</p>
				</div>
				<div className="testimony-image">
					<Img fixed={data.testimony.picture.childImageSharp.fixed} alt={title} />
				</div>
			</ContentWrapper>
		</Section>
	);
};

export default Testimony;
