import React from 'react';
import HeroHome from '@/components/HeroHome/HeroHome';
import { graphql, useStaticQuery } from 'gatsby';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import { COLORS } from '@/constants.js';

const { WHITE, GREEN, GREY } = COLORS;

const STRATES = [
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: GREEN,
		width: '25%',
		withIllu: true
	},
	{
		color: GREY,
		width: '25%'
	},
	{
		color: GREY,
		width: '25%'
	}
];

const DiscoverHome = ({ locale }) => {
	const data = useStaticQuery(
		graphql`
			query DiscoverHomeQuery {
				strapiDiscoverhomes {
					DiscoverMedia {
						poster {
							url
							id
						}
						image {
							id
							url
						}
						id
						isVideo
					}
					discoverBlock {
						CTAText
						content
						id
						link
						locales {
							locale
							localeName
						}
						title
					}
				}
			}
		`
	);
	const dataDiscover = filterLocaleItems(locale)(data.strapiDiscoverhomes.discoverBlock);
	const { poster, isVideo, image } = data.strapiDiscoverhomes.DiscoverMedia;

	return (
		<HeroHome
			{...dataDiscover}
			imgLeft
			picture={!isVideo && image}
			poster={poster}
			video={isVideo && { url: image.url }}
			CTA={dataDiscover.CTAText}
			CTALink={dataDiscover.link}
			defaultStrates={STRATES}
			textLeft
			isDiscover
		/>
	);
};

export default DiscoverHome;
