import './TabContent.scss';
import React, { useState } from 'react';
import ChevronUp from '../../images/chevron-up.inline.svg';
import ChevronDown from '../../images/chevron-down.inline.svg';
import cn from 'classnames';

const TogglableQuestion = ({ answer, question, index, active, setActive }) => {
	const isActive = active === index;

	return (
		<li className={cn('togglable-question', { isActive })} onClick={() => setActive(index)}>
			<button className="question-block">
				<div className="text">
					<div>
						<span className="text-number">{index + 1}</span>
						<span className="text-dot">.</span>
					</div>
					<div className="text-question">{question}</div>
				</div>
				{isActive ? <ChevronUp /> : <ChevronDown />}
			</button>
			<div className={cn('answer-block', { isActive })}>
				<div className="answer">{answer}</div>
			</div>
		</li>
	);
};

const TabContent = ({ content, isSelected }) => {
	const [activeQuestion, setActiveQuestion] = useState(0);
	return (
		<li className={cn('tab-content', { hidden: !isSelected })}>
			<ul>
				{content.map((item, i) => (
					<TogglableQuestion {...item} key={i} index={i} active={activeQuestion} setActive={setActiveQuestion} />
				))}
			</ul>
		</li>
	);
};

export default TabContent;
