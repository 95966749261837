import React, { useRef, useContext } from 'react';
import useVisibiltySensor from '@/hooks/useVisibiltySensor';
import './LastPost.scss';
import Section from '@/components/Section/Section';
import ContentWrapper from '@/components/ContentWrapper/ContentWrapper';
import { COLORS } from '@/constants';
import Title from '@/components/Title/Title';
import Slider from '@/components/Slider/Slider';
import { graphql, useStaticQuery } from 'gatsby';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { findLocaleItems } from '../../helpers';
import { Animated } from 'react-animated-css';
import { size } from 'lodash/fp';
import { IsMobileContext } from '../../contexts/IsMobileContext';
import cn from 'classnames';

const { LIGHTGREY, GREEN, WHITE } = COLORS;

const wording = {
	en: {
		seeMore: 'See More'
	},
	fr: {
		seeMore: 'Voir plus'
	}
};

const STRATES = [
	{
		color: LIGHTGREY,
		width: '25%'
	},
	{
		color: GREEN,
		width: '25%',
		withIllu: true
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	}
];
const STRATES_MOBILE = [
	{
		color: LIGHTGREY,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: GREEN,
		width: '25%',
		withIllu: true
	},
	{
		color: WHITE,
		width: '25%'
	}
];

const MAX_SUBTITLE_SIZE = 150;

const getTruncateString = string => {
	const stringSize = size(string);
	if (stringSize > MAX_SUBTITLE_SIZE) return string.slice(0, MAX_SUBTITLE_SIZE) + '...';
	return string;
};

const LastPost = ({ locale, blockTitle: blockTitles }) => {
	const childrenRef = useRef(null);
	const [isVisible] = useVisibiltySensor(childrenRef);
	const { isMobile } = useContext(IsMobileContext);

	const data = useStaticQuery(
		graphql`
			query HomeNewsPosts {
				news: allStrapiNewsposts(limit: 6) {
					nodes {
						twitterNews
						tweetLink
						linkedInNews
						linkedInLink
						cover {
							childImageSharp {
								fixed(width: 300) {
									...GatsbyImageSharpFixed
								}
							}
						}
						NewsGroup {
							slug
							subtitle
							title
							locales {
								localeName
								locale
							}
						}
					}
				}
			}
		`
	);
	const { title: blockTitle } = findLocaleItems(locale)(blockTitles);

	return (
		<Section strates={isMobile ? STRATES_MOBILE : STRATES} className="last-posts">
			<div ref={childrenRef}>
				<ContentWrapper>
					<Animated className="col col-title" animationIn="slideInUp" isVisible={isVisible}>
						<Title level={3}>{blockTitle}</Title>
					</Animated>
					<div className="col">
						<Animated animationIn="slideInRight" isVisible={isVisible}>
							<Slider>
								{data.news.nodes.map(({ twitterNews, tweetLink, NewsGroup, linkedInNews, linkedInLink, cover }, i) => {
									const news = filterLocaleItems(locale)(NewsGroup);
									return (
										news && (
											<div className="news-slide" key={i}>
												{cover && cover.childImageSharp && (
													<div className="cover">
														<Img fixed={cover.childImageSharp.fixed} alt={news.title} />
													</div>
												)}
												<div className={cn('article', { 'without-cover': !cover })}>
													<h4>{news.title}</h4>
													<p>{isMobile ? getTruncateString(news.subtitle) : news.subtitle}</p>
													{twitterNews || linkedInNews ? (
														<a
															className="cta-news"
															href={tweetLink || linkedInLink}
															rel="noopener noreferrer"
															target="_blank">
															{wording[locale].seeMore}
														</a>
													) : (
														<Link className="cta-news" to={news.slug}>
															{wording[locale].seeMore}
														</Link>
													)}
												</div>
											</div>
										)
									);
								})}
							</Slider>
							<a
								className="twitter-timeline"
								data-height="500"
								data-theme="light"
								href="https://twitter.com/Ombreafr?ref_src=twsrc%5Etfw">
								Tweets by Ombreafr
							</a>
						</Animated>
					</div>
				</ContentWrapper>
			</div>
		</Section>
	);
};

export default LastPost;
