import './OurResults.scss';
import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Title from '../Title/Title';
import Section from '../Section/Section';
import { findLocaleItems, isLocaleMatch, getStrates } from '../../helpers';
import { IsMobileContext } from '../../contexts/IsMobileContext';
import { compose, filter, property, size } from 'lodash/fp';
import cn from 'classnames';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import Img from 'gatsby-image';
import { sortBy } from 'lodash';

const getHightLightSpan = string => {
	const re = /[+]{0,1}\d{1,2}[%]{0,1}\s{0,1}[°]{0,1}/;
	const match = string.match(re);
	return string.replace(match, `<span>${match}</span>`);
};

const ResultItem = ({
	locale,
	TextContent,
	points,
	wineQuality,
	img,
	strates: { layout } = {},
	partnerLogo = [],
	partnersTitle = [],
	isWine,
	isRight,
	index,
	title: blockTitle
}) => {
	const { title, paragraph } = findLocaleItems(locale)(TextContent);
	const listItem = filter(isLocaleMatch(locale))(points);
	const wineQualityList = filter(isLocaleMatch(locale))(wineQuality);
	const { isMobile } = useContext(IsMobileContext);
	const localizedPartnersTitle = compose(property('title'), findLocaleItems(locale))(partnersTitle);

	return (
		<Section
			className={cn('result-item', {
				'with-potager': index === 0,
				'with-grappe': index === 1,
				'with-flower': index === 2,
				'without-states': index !== 0
			})}
			strates={getStrates(layout)}>
			<ContentWrapper
				className={cn('result-item-wrapper', {
					'img-left': isRight
				})}>
				{blockTitle && (
					<Title className="result-item__block-title" level={2}>
						{blockTitle}
					</Title>
				)}
				<div className="result-item-text">
					<Title level={3} className={'result-item-title'}>
						{title}
					</Title>
					{paragraph && <div className="subtitle">{paragraph}</div>}
					<ul className="list">
						{!!size(wineQualityList) && (
							<div className="list-quality">
								{wineQualityList.map(({ title, value }, i) => (
									<li key={i}>
										<p className="list-item list-quality-title">{title}</p>
										<p className="list-quality-value">{value}</p>
									</li>
								))}
							</div>
						)}
						{listItem.map(({ string }, i) => (
							<li className="list-item" key={i} dangerouslySetInnerHTML={{ __html: getHightLightSpan(string) }} />
						))}
					</ul>
					{localizedPartnersTitle && (
						<div className="result-item-partners">
							<Title level={3} className={'result-item-title'}>
								{localizedPartnersTitle}
							</Title>
							<div className="result-item-partners-logo">
								{partnerLogo &&
									partnerLogo.map(({ url }, i) => (
										<div key={i} className="container">
											<img src={url} alt={localizedPartnersTitle} />
										</div>
									))}
							</div>
						</div>
					)}
				</div>
				<div className="result-item-picture">
					{!isMobile ? (
						<Img className="cover" alt={title} fixed={img.childImageSharp.fixed} />
					) : (
						<Img className="cover" alt={title} fluid={img.childImageSharp.fluid} />
					)}
				</div>
			</ContentWrapper>
		</Section>
	);
};

const OurResults = ({ locale, blockTitle }) => {
	const data = useStaticQuery(graphql`
		{
			allStrapiResultblockitems {
				nodes {
					partnerLogo {
						url
					}
					partnersTitle {
						locales {
							locale
						}
						title
					}
					order
					TextContent {
						paragraph
						title
						locales {
							locale
						}
					}
					points {
						string
						locales {
							locale
						}
					}
					wineQuality {
						value
						title
						locales {
							locale
						}
					}
					img {
						childImageSharp {
							fixed(width: 500) {
								...GatsbyImageSharpFixed
							}
							fluid(maxWidth: 200, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					strates {
						layout
					}
					isWine
					isRight
				}
			}
		}
	`);
	const { nodes } = data.allStrapiResultblockitems;
	const sortedItems = sortBy(nodes, ({ order }) => order);
	const { title } = findLocaleItems(locale)(blockTitle);
	return (
		<div className="our-results" id="results">
			{sortedItems.map((item, i) => (
				<ResultItem {...item} key={i} index={i} locale={locale} title={i === 0 && title} />
			))}
		</div>
	);
};

export default OurResults;
