import React from 'react';
import Layout from '@/components/Layout/Layout';
import HeroHome from '@/components/HeroHome/HeroHome';
import { IsMobileProvider } from '@/contexts/IsMobileContext';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import { COLORS, BLOCK_NAMES } from '@/constants.js';
import { orderBy } from 'lodash';
import { compose, find, property } from 'lodash/fp';
import Distinctions from '@/components/Distinctions/Distinctions';
import Supports from '@/components/Supports/Supports';
import OmbreaSystemSlider from '@/components/OmbreaSystemSlider/OmbreaSystemSlider';
import LastPost from '@/components/LastPost/LastPost';
import EditorialHome from '@/components/EditorialHome/EditorialHome';
import DiscoverHome from '@/components/DiscoverHome/DiscoverHome';
import FeaturesPoints from '@/components/FeaturesPoints/FeaturesPoints';
import Team from '@/components/Team/Team';
import Values from '@/components/Values/Values';
import AvantageSlider from '@/components/AvantageSlider/AvantageSlider';
import SiteType from '@/components/SiteType/SiteType';
import SubsidiaryBlock from '@/components/SubsidiaryBlock/SubsidiaryBlock';
import Testimony from '@/components/Testimony/Testimony';
import NewsPostList from '@/components/NewsPostList/NewsPostList';
import SciencePartnership from '../components/SciencesPartnership/SciencesPartnership';
import OurResults from '../components/OurResults/OurResults';
import FAQ from '../components/FAQ/FAQ';
import AgronomicExpertise from '@/components/AgronomicExpertise/AgronomicExpertise';

const { WHITE, LIGHTGREY, GREEN } = COLORS;
const {
	SUBSIDIARY,
	AVANTAGE_SLIDER,
	OMBREA_SYSTEM_SLIDER,
	SUPPORTS,
	DISTINCTIONS,
	NEWS,
	DISCOVER_HOMEBLOCK,
	HOME_EDITORIAL,
	FEATURES_POINTS,
	VALUES,
	TEAM,
	SCIENCES_PARTNERSHIP,
	SITE_TYPE,
	TESTIMONY,
	NEWS_POST_LIST,
	OUR_RESULTS,
	FAQ_BLOCK,
	AGRONOMIC_EXPERTISE
} = BLOCK_NAMES;

const STRATES = [
	{
		color: LIGHTGREY,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: GREEN,
		width: '25%',
		withIllu: true
	},
	{
		color: WHITE,
		width: '25%'
	}
];

// Hack to fix strapi not giving access to blockTitle content type in page request
const getBlockTitle = name =>
	compose(
		property('blockTitle'),
		find(({ name: nameFromBlockRequest }) => name === nameFromBlockRequest)
	);

const Page = ({ pageContext }) => {
	const { blocks, link } = pageContext;
	return (
		<IsMobileProvider>
			<Layout locale={pageContext.locale} url={pageContext.url} activeLink={link}>
				{pageContext.pageHeroMedia && (
					<HeroHome
						isHero
						pageName={pageContext.PageName}
						locale={pageContext.locale}
						video={pageContext.pageHeroMedia.isVideo && pageContext.pageHeroMedia.image}
						poster={property('pageHeroMedia.poster')(pageContext)}
						picture={!pageContext.pageHeroMedia.isVideo && pageContext.pageHeroMedia.image}
						{...filterLocaleItems(pageContext.locale)(pageContext.pageHero)}
						defaultStrates={STRATES}
					/>
				)}
				{orderBy(pageContext.pageBlock, ['order'], ['asc']).map(({ block, order }, i) => {
					switch (block.name) {
						case OMBREA_SYSTEM_SLIDER: {
							return (
								<OmbreaSystemSlider
									key={i}
									locale={pageContext.locale}
									blockTitle={getBlockTitle(OMBREA_SYSTEM_SLIDER)(blocks)}
								/>
							);
						}
						case AVANTAGE_SLIDER: {
							return (
								<AvantageSlider
									key={i}
									locale={pageContext.locale}
									blockTitle={getBlockTitle(AVANTAGE_SLIDER)(blocks)}
								/>
							);
						}
						case SUPPORTS: {
							return (
								<Supports
									locale={pageContext.locale}
									key={i}
									blockTitle={getBlockTitle(SUPPORTS)(blocks)}
									pageName={pageContext.PageName}
								/>
							);
						}
						case DISTINCTIONS: {
							return (
								<Distinctions locale={pageContext.locale} key={i} blockTitle={getBlockTitle(DISTINCTIONS)(blocks)} />
							);
						}
						case NEWS: {
							return <LastPost key={i} locale={pageContext.locale} blockTitle={getBlockTitle(NEWS)(blocks)} />;
						}
						case DISCOVER_HOMEBLOCK: {
							return <DiscoverHome key={i} locale={pageContext.locale} />;
						}
						case AGRONOMIC_EXPERTISE: {
							return <AgronomicExpertise key={i} locale={pageContext.locale} />;
						}
						case HOME_EDITORIAL: {
							return <EditorialHome key={i} locale={pageContext.locale} />;
						}
						case FEATURES_POINTS: {
							return <FeaturesPoints key={i} locale={pageContext.locale} />;
						}
						case TEAM: {
							return <Team key={i} locale={pageContext.locale} blockTitle={getBlockTitle(TEAM)(blocks)} />;
						}
						case VALUES: {
							return <Values key={i} locale={pageContext.locale} blockTitle={getBlockTitle(VALUES)(blocks)} />;
						}
						case SITE_TYPE: {
							return <SiteType key={i} locale={pageContext.locale} blockTitle={getBlockTitle(SITE_TYPE)(blocks)} />;
						}
						case SUBSIDIARY: {
							return (
								<SubsidiaryBlock key={i} locale={pageContext.locale} blockTitle={getBlockTitle(SUBSIDIARY)(blocks)} />
							);
						}
						case TESTIMONY: {
							return <Testimony key={i} locale={pageContext.locale} blockTitle={getBlockTitle(TESTIMONY)(blocks)} />;
						}
						case NEWS_POST_LIST: {
							return (
								<NewsPostList key={i} locale={pageContext.locale} blockTitle={getBlockTitle(NEWS_POST_LIST)(blocks)} />
							);
						}
						case SCIENCES_PARTNERSHIP: {
							return <SciencePartnership key={i} locale={pageContext.locale} />;
						}
						case OUR_RESULTS: {
							return <OurResults key={i} locale={pageContext.locale} blockTitle={getBlockTitle(OUR_RESULTS)(blocks)} />;
						}
						case FAQ_BLOCK: {
							return <FAQ key={i} locale={pageContext.locale} />;
						}
						default:
							return;
					}
				})}
			</Layout>
		</IsMobileProvider>
	);
};

export default Page;
