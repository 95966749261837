import React from 'react';
import Img from 'gatsby-image';

const BubbleSlide = ({ items }) => (
	<div className="bubble-slide">
		{items.map(({ title, name, logo }, i) => (
			<div className="bubble-logo" key={i}>
				<Img alt={name} key={i} fluid={logo.childImageSharp.fluid} />
			</div>
		))}
	</div>
);

export default BubbleSlide;
