import './ScrollSlider.scss';
import React, { useEffect, useState } from 'react';
import Title from '@/components/Title/Title';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import Slider from '@/components/Slider/Slider';
import { COLORS } from '../../constants';
import { size, property } from 'lodash/fp';
import cn from 'classnames';
import { filterLocaleNodes, findLocaleItems, filterLocaleItems } from '../../helpers';
import Section from '../Section/Section';
import { SLIDER_DEFAULT_SETTING } from '@/constants.js';

const { GREY, WHITE } = COLORS;
const STRATES = [
	{
		color: GREY,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: GREY,
		width: '25%'
	}
];

const renderSlide = ({ slideTitle: { slideTitle }, img, imgAvantage, points }, i, locale) => {
	const localisedPoints = filterLocaleItems(locale)(points);
	return (
		<div className={cn('slide')} key={i}>
			<div className="slide__img">
				<img
					src={property('childImageSharp.fixed.src')(img) || property('childImageSharp.fixed.src')(imgAvantage)}
					className="cover"
					alt={slideTitle}
				/>
			</div>
			<div className="slide__text">
				<div className="slide__title">
					<p className="slide__title-number">
						{i + 1}
						<span className="slide__title-dot">.</span>
					</p>
					<p className="slide__title-text">{slideTitle}</p>
				</div>
				{localisedPoints && (
					<ul className="slide__points">
						{localisedPoints.map(({ string }, i) => (
							<li key={i}>{string}</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
};

const ScrollSlider = ({ locale, blockTitle, sliderData }) => {
	const localizedNodes = filterLocaleNodes(locale, 'slideTitle')(sliderData);
	const { title } = findLocaleItems(locale)(blockTitle);
	return (
		<Section className="scroll-magic-slider" strates={STRATES}>
			<Title level={1} className="scroll-magic-slider__title">
				{title}
			</Title>
			<Slider options={SLIDER_DEFAULT_SETTING}>{localizedNodes.map((item, i) => renderSlide(item, i, locale))}</Slider>
		</Section>
	);
};

export default ScrollSlider;
