import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ScrollSlider from '../ScrollSlider/ScrollSlider';

const OmbreaSystemSlider = props => {
	const sliderData = useStaticQuery(graphql`
		{
			allStrapiOmbreasystems(sort: { fields: slideOrder }) {
				nodes {
					id
					slideTitle {
						slideTitle
						slideSubtitle
						locales {
							locale
						}
					}
					img {
						childImageSharp {
							fixed {
								src
							}
						}
					}
				}
			}
		}
	`);

	return <ScrollSlider sliderData={sliderData.allStrapiOmbreasystems.nodes} {...props} />;
};

export default OmbreaSystemSlider;
