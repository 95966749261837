import BubbleSliderBlock from '@/components/BubbleSliderBlock/BubbleSliderBlock';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { chunk } from 'lodash';
import { COLORS } from '@/constants.js';
import { findLocaleItems } from '../../helpers';

const { LIGHTGREY, WHITE, GREEN } = COLORS;

const strates = [
	{
		color: LIGHTGREY,
		width: '25%'
	},
	{
		color: GREEN,
		width: '25%',
		withIllu: true
	},
	{
		color: WHITE,
		width: '25%'
	},
	{
		color: WHITE,
		width: '25%'
	}
];

const Distinctions = ({ locale, blockTitle: blockTitles }) => {
	const data = useStaticQuery(
		graphql`
			query DistinctionsQuery {
				distinctions: allStrapiDistinctions {
					nodes {
						title
						result
						logoDistinctions {
							childImageSharp {
								fluid(maxWidth: 150, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		`
	);
	const dataDistinctions = data.distinctions.nodes.map(({ logoDistinctions, ...rest }) => ({
		logo: logoDistinctions,
		...rest
	}));
	const slidesData = chunk(dataDistinctions, 5);
	const { title: blockTitle } = findLocaleItems(locale)(blockTitles);
	return (
		<BubbleSliderBlock
			key="distinctions"
			title={blockTitle}
			slides={slidesData}
			sliderPosition="RIGHT"
			strates={strates}
		/>
	);
};

export default Distinctions;
