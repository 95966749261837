import React, { useRef } from 'react';
import './HeroHome.scss';
import Title from '../Title/Title';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import CallToAction from '../CallToAction/CallToAction';
import Section from '@/components/Section/Section';
import Img from 'gatsby-image';
import IlluSocial from '@/images/illu_social.inline.png';
import cn from 'classnames';
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer';
import { Animated } from 'react-animated-css';
import { property } from 'lodash/fp';
import { getStrates } from '@/helpers';
import useVisibiltySensor from '@/hooks/useVisibiltySensor';

const HeroHome = ({
	isDiscover,
	isAgronomic,
	title,
	content,
	subtitle,
	CTA,
	CTALink,
	picture,
	textLeft,
	defaultStrates,
	strates,
	withLeaf,
	hasIllu,
	pageName,
	locale,
	video,
	poster,
	isHero
}) => {
	const childrenRef = useRef(null);
	const [isVisible] = useVisibiltySensor(childrenRef, 0);
	const layout = property('layout')(strates);
	const videoHome = '2L7tAYY3-c0';
	const videoSystem = 'G02BNETylHQ';
	// const videoSystem = 'aI3RMN1ES7s';

	return (
		<Section
			className={cn('hero-home', {
				'animate-in': isVisible,
				'with-leaf': withLeaf,
				'with-illu': hasIllu,
				'is-hero': isHero,
				'is-home': pageName === 'Home',
				'is-system': pageName === 'system',
				'is-agronomic': isAgronomic
			})}
			strates={layout ? getStrates(layout) : defaultStrates}
			isDiscover={isDiscover}
			isHero
			id={!isHero ? 'video' : ''}>
			<div ref={childrenRef}>
				{hasIllu && (
					<img src={IlluSocial} className={cn('illustration', { 'illustration--left': textLeft })} alt="Social" />
				)}
				<ContentWrapper
					className={cn('hero-home-wrapper', {
						'img-left': textLeft
					})}>
					<Animated animationIn="fadeInUpBig" isVisible={isVisible || isHero} className="hero-text">
						<Title level={2}>{title}</Title>
						{content && <div className="content">{content}</div>}
						{subtitle && <div className="subtitle">{subtitle}</div>}
						{CTA && (
							<CallToAction href={pageName === 'Home' && locale === 'en' ? '/system' : CTALink}>{CTA}</CallToAction>
						)}
					</Animated>

					<Animated
						animationIn={textLeft ? 'slideInLeft' : 'slideInRight'}
						isVisible={isVisible || isHero}
						animationInDelay={200}
						className="hero-home-picture">
						{video ? (
							<VideoPlayer youtubeUrl={pageName === 'system' ? videoSystem : videoHome} loop={pageName === 'system'} />
						) : (
							<>
								{picture && picture.childImageSharp ? (
									<Img className="cover" alt={title} fixed={picture.childImageSharp.fixed} />
								) : (
									picture && <img className="cover" src={picture.url} alt={title} />
								)}
							</>
						)}
					</Animated>
				</ContentWrapper>
			</div>
		</Section>
	);
};

export default HeroHome;
