import React from 'react';
import './VideoPlayer.scss';

const VideoPlayer = ({ youtubeUrl, loop }) => {
	const loopVideoSettings = loop ? `&autoplay=1&mute=1&loop=1&playlist=${youtubeUrl}` : '';
	return (
		<div>
			<iframe
				title="Ombrea"
				width="560"
				height="315"
				src={`https://www.youtube-nocookie.com/embed/${youtubeUrl}?controls=0&disablekb=1&fs=0&rel=0&showinfo=0${loopVideoSettings}`}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen></iframe>
		</div>
	);
};

export default VideoPlayer;
